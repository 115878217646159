import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Platform, AlertController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import {
  AuthUser,
  NewCityAccountUser,
  TOKEN_KEY,
  REFRESH_KEY,
} from "src/app/models/auth.model";
import { HttpClient } from "@angular/common/http";
import { SERVER_URL } from "src/environments/environment";
import { Router, UrlSegment } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from "rxjs/operators";

const helper = new JwtHelperService();

export enum AuthType {
  None = "",
  City = "city",
  Supplier = "supplier",
  Internal = "internal",
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private unautorizedAlert: HTMLIonAlertElement;

  authType: BehaviorSubject<AuthType> = new BehaviorSubject(null);
  emailVerified: BehaviorSubject<string> = new BehaviorSubject(null);

  returnUrl: string;

  constructor(
    private storage: Storage,
    private plt: Platform,
    private http: HttpClient,
    private alertCtrl: AlertController
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then((res) => {
      if (res) {
        // ADD EXPIRED TOKEN CHECK
        const decoded = helper.decodeToken(res);
        const type = decoded.sub.type;
        this.setNewAuthState(type);
        this.emailVerified.next(
          decoded.sub.verified ? null : decoded.sub.email
        );
      } else {
        this.setNewAuthState(AuthType.None);
      }
    });
  }

  login(user: AuthUser, success: () => void, error: (data: any) => void) {
    this.http
      .post(`${SERVER_URL}/auth/login`, user)
      .subscribe(
        (result: {
          ok: boolean;
          token: string;
          refresh: string;
          verified: boolean;
        }) => {
          this.storage.set(REFRESH_KEY, result.refresh);
          this.storage.set(TOKEN_KEY, result.token).then(() => {
            const decoded = helper.decodeToken(result.token);
            console.log(decoded);
            const type = decoded.sub.type;
            this.setNewAuthState(type);
            this.emailVerified.next(
              decoded.sub.verified ? null : decoded.sub.email
            );
            success.apply(null);
          });
        },
        error
      );
  }

  refreshToken(): Observable<{ token: string }> {
    return this.http.post(`${SERVER_URL}/auth/refresh`, null) as Observable<{
      token: string;
    }>;
  }

  setNewAuthState(type: AuthType | string) {
    switch (type) {
      case AuthType.City:
        this.authType.next(AuthType.City);
        break;
      case AuthType.Supplier:
        this.authType.next(AuthType.Supplier);
        break;
      case AuthType.Internal:
        this.authType.next(AuthType.Internal);
        break;
      default:
        this.authType.next(AuthType.None);
        break;
    }
  }

  unauthorizedLogout() {
    this.alertCtrl
      .create({
        header: "Error",
        message:
          "It appears your session is invalid or has expired. Please login again.",
        mode: "ios",
        buttons: [
          {
            text: "Okay",
            role: "cancel",
          },
        ],
      })
      .then((a) => {
        if (!this.unautorizedAlert) {
          this.unautorizedAlert = a;
          this.unautorizedAlert.present();
          this.unautorizedAlert
            .onDidDismiss()
            .then(() => (this.unautorizedAlert = null));
        }
      });
    this.logout();
  }

  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authType.next(AuthType.None);
    });
  }

  isAuthenticated() {
    return (
      this.authType.value === AuthType.City ||
      this.authType.value === AuthType.Supplier ||
      this.authType.value === AuthType.Internal
    );
  }

  isAuthenticatedType(type: AuthType) {
    return this.authType.value === type;
  }

  getAuthType() {
    return this.authType.value;
  }

  requestResetPassword(
    email: string,
    success: (data: any) => void,
    error: (data: any) => void
  ) {
    this.http
      .post(`${SERVER_URL}/public/request-password-reset`, {
        email,
      })
      .subscribe(success, error);
  }

  resetPassword(
    email: string,
    code: string,
    password: string,
    confirmPassword: string,
    success: (data: any) => void,
    error: (data: any) => void
  ) {
    this.http
      .post(`${SERVER_URL}/public/reset-password`, {
        email,
        code,
        password,
        confirmPassword,
      })
      .subscribe(success, error);
  }

  createCityAccount(
    user: NewCityAccountUser,
    success: (data: any) => void,
    error: (data: any) => void
  ) {
    this.http
      .post(`${SERVER_URL}/public/city/create-account`, user)
      .subscribe(success, error);
  }

  verifyCityEmail(
    accountId: string,
    code: string,
    success: (data: any) => void,
    error: (data: any) => void
  ) {
    this.http
      .post(`${SERVER_URL}/public/city/verify-account`, {
        a: accountId,
        c: code,
      })
      .subscribe(success, error);
  }

  resendCityVerificationEmail(
    accountId: string,
    success: (data: any) => void,
    error: (data: any) => void
  ) {
    this.http
      .post(`${SERVER_URL}/public/city/resend-verification`, {
        a: accountId,
      })
      .subscribe(success, error);
  }
}
