import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { Observable, from, BehaviorSubject, throwError } from "rxjs";
import {
  switchMap,
  mergeMap,
  catchError,
  filter,
  take,
  timeout,
} from "rxjs/operators";
import { AuthService } from "../authentication/auth.service";
import { TOKEN_KEY, REFRESH_KEY } from "src/app/models/auth.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";

const helper = new JwtHelperService();

@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject(null);

  constructor(private storage: Storage, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const key = request.url.includes("/auth/refresh") ? REFRESH_KEY : TOKEN_KEY;
    return from(this.storage.get(key)).pipe(
      mergeMap((token) => {
        const newReq = this.addHeaders(request, token);
        const time = newReq.headers.get("Timeout");
        return next.handle(newReq).pipe(
          timeout(time ? Number(time) : 60000),
          catchError((error) => {
            if (request.url.includes("/auth/refresh")) {
              console.error("REFRESH ERROR");
              this.authService.unauthorizedLogout();
              return throwError(error);
            }

            if (request.url.endsWith("/auth/login")) {
              console.error("LOGIN ERROR");
              return throwError(error);
            }

            if (error.status === 403) {
              console.error("INVALID CREDENTIALS");
              this.authService.unauthorizedLogout();
              return throwError(error);
            } else if (error.status !== 401) {
              return throwError(error);
            }

            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter((result) => result != null),
                take(1),
                switchMap((newToken) =>
                  next.handle(this.addHeaders(request, newToken))
                )
              );
            } else {
              return this.refreshToken(request, next);
            }
          })
        );
      })
    );
  }

  refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);

    return from(this.authService.refreshToken()).pipe(
      switchMap((result) => {
        return from(
          this.storage.set(TOKEN_KEY, result.token).then(() => {
            const decoded = helper.decodeToken(result.token);
            const type = decoded.sub.type;
            this.authService.setNewAuthState(type);
            this.authService.emailVerified.next(
              decoded.sub.verified ? null : decoded.sub.email
            );
            return result.token;
          })
        ).pipe(
          switchMap((token) => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addHeaders(request, token));
          })
        );
      }),
      catchError((error) => {
        this.refreshTokenInProgress = false;
        this.authService.unauthorizedLogout();
        return throwError(error);
      })
    );
  }

  private addHeaders(request: HttpRequest<any>, token: any) {
    let headers: any = {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Site: environment.site,
    };
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    let clone = request.clone({
      setHeaders: headers,
    });
    return clone;
  }
}
