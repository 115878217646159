import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CityAuthGuard } from './guards/city/city-auth.guard';
import { SupplierAuthGuard } from './guards/supplier/supplier-auth.guard';
import { InternalAuthGuard } from './guards/internal/internal-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'city',
    canActivate: [CityAuthGuard],
    loadChildren: () => import('./city/city-routing.module').then( m => m.CityRoutingModule)
  },
  {
    path: 'supplier',
    canActivate: [SupplierAuthGuard],
    loadChildren: () => import('./supplier/supplier-routing.module').then( m => m.SupplierRoutingModule)
  },
  {
    path: 'internal',
    canActivate: [InternalAuthGuard],
    loadChildren: () => import('./internal/internal-routing.module').then( m => m.InternalRoutingModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./public/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./public/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./public/create-account/create-city-account/create-city-account.module').then( m => m.CreateCityAccountPageModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./public/create-account/verify-city-email/verify-city-email.module').then( m => m.VerifyCityEmailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./public/create-account/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./public/create-account/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'early-access',
    loadChildren: () => import('./public/internal/grant-access/grant-access.module').then( m => m.GrantAccessPageModule)
  },
  {
    path: '**',
    redirectTo: 'landing',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
