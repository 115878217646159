import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private http: HttpClient,
  ) { }

  logPageLoad(event: { id: number, page: string }) {
    return this.http.post(`${SERVER_URL}/log/page-load`, event);
  }
}
