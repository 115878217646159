export const TOKEN_KEY = 'SCMP_TOKEN';
export const REFRESH_KEY = 'SCMP_REFRESH';

export interface AuthUser {
    email: string,
    password: string,
}

export interface NewCityAccountUser {
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    password: string,
    confirmPassword: string,
}