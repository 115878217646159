import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService, AuthType } from './services/authentication/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from './services/logging/logging.service';
import { GeneralService } from './services/general/general.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private logService: LoggingService,
    private generalService: GeneralService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.router.events.subscribe((e) => {
        const event = (e as any);
        if (
          event.id
          && event.url
          && event.urlAfterRedirects
          && !event.state
          && !event.navigationTrigger) {
            this.logService.logPageLoad({ id: event.id, page: event.url }).subscribe()
        }
      });

      this.authService.authType.subscribe(state => {
        if (!this.generalService.afterLaunch()) {
          const url = this.router.url.split('/');
          const end = url.pop()
          if (url && end && end != 'early-access') {
            this.router.navigate(['landing'])
          }
        } else {
          const url = this.router.url.split('/');
          const urlBase = url.length > 0 ? url[1] : '';
          const returnUrl = this.authService.returnUrl;
          const returl = returnUrl ? returnUrl.split('/') : [];
          const returnUrlBase = returl.length > 0 ? returl[1] : '';
          switch(state){
            case AuthType.City:
              if (returnUrl) {
                this.authService.returnUrl = null;
                if (returnUrlBase === 'city') {
                  this.router.navigate([returnUrl]);
                  break;
                }
              }
              if (urlBase && (urlBase !== 'city' && !this.redirectBlacklist(urlBase))) {
                this.router.navigate(['city']);
              }
              break;
            case AuthType.Supplier:
              if (returnUrl) {
                this.authService.returnUrl = null;
                if (returnUrlBase === 'supplier') {
                  this.router.navigate([returnUrl]);
                  break;
                }
              }
              if (urlBase && (urlBase !== 'supplier' && !this.redirectBlacklist(urlBase))) {
                this.router.navigate(['supplier']);
              }
              break;
            case AuthType.Internal:
              if (returnUrl) {
                this.authService.returnUrl = null;
                if (returnUrlBase === 'internal') {
                  this.router.navigate([returnUrl]);
                  break;
                }
              }
              if (urlBase && (urlBase !== 'internal' && !this.redirectBlacklist(urlBase))) {
                this.router.navigate(['internal']);
              }
              break;
            case AuthType.None:
              if (urlBase === 'city' || urlBase === 'supplier' || urlBase === 'internal') {
                this.router.navigate(['login']);
              }
              break;
            default:
              break;
          }
        }
      })
    });
  }

  redirectBlacklist(urlBase: string) {
    if (urlBase.startsWith('verify')) return true;
    if (urlBase.startsWith('forgot-password')) return true;
    if (urlBase.startsWith('reset-password')) return true;
    if (urlBase.startsWith('early-access')) return true;
    return false;
  }
}
