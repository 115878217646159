export interface MultiselectGridItem {
    value: string;
    icon: string;
    label: string;
    detail: string;
    info?: string;
}

export interface ValueLabelPair {
    value: string,
    label: string | number,
}

export interface IDLabelPair {
    id: number,
    label: string,
}

export interface DropdownOption {
    id: number | string,
    label: string,
    type?: string
}

export interface BundleOption {
  id: number,
  label: string,
  bundle: {
    items: {
      label: string,
      quantity: number
    }[],
  }
  open?: boolean,
}

export interface BundleField {
  options?: BundleOption[],
  placeholder: string,
  inputType?: string,
  type: FormInputType,
  reference: string,
  grow: string,
  name: string,
  description: string,
}

export enum FormInputType {
    DropDown = 'dropdown',
    BundleSelect = 'bundle-select',
    Input = 'input',
    ButtonSelect = 'button-select',
    Checkbox = 'checkbox',
    MultiItemList = 'multi-item-list',
    Label = 'label',
    NutrioDigitalWellness = 'nutrio-digital-wellness',
}

export enum HelpModalClass {
    Tiny = 'tiny-help-modal',
    Small = 'small-help-modal',
    Medium = 'medium-help-modal',
    Large = 'large-help-modal'
}

export interface UseCaseField {
    reference: string,
    useCase?: string,
    label: string,
    type: FormInputType,
    options?: ValueLabelPair[],
    placeholder?: string,
    default?: string,
    conditions?: Condition | ConditionGroup,
    fields?: (DropDownListItem | InputListItem | CheckboxListItem | LabelListItem | BundleField)[],
    multiple?: boolean,
    hidden?: boolean,
    value?: any,
    unit?: string,
    help?: {
        route: string,
        class: HelpModalClass
    },
}

export interface UseCaseProductDetail {
    reference: string;
    fields: UseCaseField[]
}

export enum ConditionType {
    And = 'and',
    Or = 'or',
}

export interface Condition {
    reference: string,
    value: string,
}

export interface ConditionGroup {
    type: ConditionType,
    conditions?: Condition[],
    groups?: ConditionGroup[],
}

export interface ListItem {
    type: FormInputType,
    reference: string,
    grow: string,
    value?: string,
    error?: string;
}

export interface DropDownListItem extends ListItem {
    placeholder: string,
    options?: ValueLabelPair[],
}

export interface InputListItem extends ListItem {
    placeholder: string,
    inputType?: string,
    unit?: string,
    min?: number,
    max?: number,
    required?: boolean,
}

export interface CheckboxListItem extends ListItem {
    label: string,
}

export interface LabelListItem extends ListItem {
    label: string,
}
