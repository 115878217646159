import { MultiselectGridItem, UseCaseProductDetail, FormInputType, ConditionType, HelpModalClass, ValueLabelPair } from 'src/app/models/date-types.model';

export const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const phoneNumberRegex = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);

export const standardUseCases: MultiselectGridItem[] = [
    {
        value: 'smart-corridor',
        icon: '/assets/icons/lightbulb.svg',
        label: 'Smart Corridor',
        detail: 'Lighting, Pole, Sensors, Integrated Operating Center',
    },{
        value: 'connectivity',
        icon: '/assets/icons/wifi.svg',
        label: 'Connectivity',
        detail: 'Fiber, Small Cell, Private LTE, WiFi-6',
    },{
    //     value: 'savings-share',
    //     icon: 'business',
    //     label: 'Savings Share',
    //     detail: 'Estimated Savings on Buildings, Fleet & Infrastructure',
    // },{
        value: 'iot',
        icon: '/assets/icons/globe.svg',
        label: 'IOT Technologies',
        detail: 'Smart Parking, Smart Infrastructure, Smart Grid, Safety and Security',
    },{
        value: 'remote-tech',
        icon: '/assets/icons/computer.svg',
        label: 'Resilience & Sustainability', // ESG (Environmental, Sustainability, Governance)
        detail: 'EV Charging, Alternative Energy Grids, Telemedicine, Remote Technologies',
    }
];

export const estimateUseCases: ValueLabelPair[] = [
    {
        value: 'smart-corridor',
        label: 'Smart Corridor',
    }, {
        value: 'connectivity-broadband',
        label: 'Connectivity - Broadband',
    }, {
        value: 'connectivity-cellular',
        label: 'Connectivity - Cellular',
    }, {
        value: 'connectivity',
        label: 'Connectivity',
    }, {
        value: 'savings-share',
        label: 'Savings Share',
    }, {
        value: 'iot',
        label: 'IOT Technologies',
    }, {
        value: 'remote-tech',
        label: 'Resilience & Sustainability',
    }
];

export const existingUseCases: any[] = [
    {
        value: 'savings-share-lighting-interior',
        icon: '/assets/icons/lightbulb.svg',
        label: 'Savings Share (Interior Lighting)',
    },{
        value: 'savings-share-lighting-exterior',
        icon: '/assets/icons/lightbulb.svg',
        label: 'Savings Share (Exterior Lighting)',
    },{
        value: 'savings-share-infrastructure',
        icon: 'business',
        label: 'Savings Share (Infrastructure)',
    }
];

export const timeframeOptions: ValueLabelPair[] = [
    {value: '<90', label: 'Less Than 90 days'},
    {value: '90-180', label: '90 - 180 days'},
    {value: '180-360', label: '180 - 360 days'},
    {value: '360+', label: 'Greater Than 360 days'}
];

export const desiredOutcomeOptions: ValueLabelPair[] = [
    {value: 'advisement', label: 'Project Advisement'},
    {value: 'implementation', label: 'Implementation'},
    {value: 'creative-funding', label: 'Outcome Based Funding'},
    {value: 'solution-as-service', label: 'Solution as a Service (City Funded)'},
    {value: 'turn-key', label: 'Turn-Key Solution (Includes All)'},
];

export const standardStatusLabels = {
    incomplete: 'Incomplete',
    complete: 'Complete',
    submitted: 'Submitted',
};

export const standardStatusColors = {
    incomplete: '#FF0000',
    complete: '#37A000',
    submitted: '#BBBBBB',
};

export const newProductDetails: UseCaseProductDetail[] = [
    {
        reference: 'smart-corridor',
        fields: [
            {
                reference: 'lights',
                label: 'Smart Lighting',
                type: FormInputType.MultiItemList,
                multiple: true,
                help: {
                    route: '/assets/help-pages/estimate/products/smart-lighting.html',
                    class: HelpModalClass.Tiny
                },
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'lighting-infrastructure-label',
                label: 'Smart Lighting Infrastructure',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/smart-lighting-infrastructure.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'light-controller',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'light-node',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'smart-pole',
                label: 'Smart Pole',
                type: FormInputType.MultiItemList,
                multiple: true,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    }
                ]
            },
            {
                reference: 'smart-pole-sensor',
                label: 'Smart Pole Sensors',
                type: FormInputType.MultiItemList,
                multiple: true,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    }
                ]
            },
            {
                reference: 'threat-detection',
                label: 'Threat Detection',
                type: FormInputType.MultiItemList,
                multiple: false,
                // help: {
                //     route: '/assets/help-pages/estimate/products/threat-detection.html',
                //     class: HelpModalClass.Tiny
                // },
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'big-data-label',
                label: 'Big Data Connection',
                type: FormInputType.Label,
            },
            {
                reference: 'connection',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    }
                ]
            },
            {
                reference: 'support-services-label',
                label: 'Support Services',
                type: FormInputType.Label,
            },
            {
                reference: 'network-management',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Years',
                        unit: 'years',
                        min: 10,
                        max: 20,
                    },
                ]
            },
            {
                reference: 'ongoing-maintenance',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Years',
                        unit: 'years',
                        min: 10,
                        max: 20,
                    },
                ]
            },
        ]
    },
    {
        reference: 'connectivity',
        fields: [
            // {
            //     reference: 'bundles',
            //     label: 'Connectivity Bundles',
            //     type: FormInputType.MultiItemList,
            //     multiple: true,
            //     help: {
            //         route: '/assets/help-pages/estimate/products/connectivity-bundles.html',
            //         class: HelpModalClass.Small
            //     },
            //     fields: [
            //         {
            //             type: FormInputType.BundleSelect,
            //             reference: 'id',
            //             grow: '1',
            //             placeholder: 'Select Bundle',
            //             name: 'Connectivity Bundles',
            //             description: 'Connectivity Bundles allow you to get your project off the ground even faster. The bundles include all the parts required for the chosen system.',
            //         },{
            //             type: FormInputType.Input,
            //             reference: 'quantity',
            //             inputType: 'number',
            //             grow: '0',
            //             placeholder: 'Quantity',
            //             unit: 'each',
            //         // },{
            //         //     type: FormInputType.Checkbox,
            //         //     reference: 'install',
            //         //     grow: '0',
            //         //     label: 'Install',
            //         },
            //     ]
            // },
            {
                reference: 'broadband-label',
                label: 'Broadband',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/broadband.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'broadband-fiber',
                label: '',
                type: FormInputType.MultiItemList,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        label: 'Broadband',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '5',
                        placeholder: 'Miles',
                        unit: 'miles',
                    },
                ]
            },
            {
                reference: 'broadband-home',
                label: '',
                type: FormInputType.MultiItemList,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        label: 'Broadband',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '5',
                        placeholder: 'Quantity',
                        unit: 'each',
                    }
                ]
            },
            {
                reference: 'wireless-label',
                label: 'Wireless',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/wireless.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'wifi-6',
                label: '',
                type: FormInputType.MultiItemList,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        label: 'WiFi-6',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '5',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'in-building-small-cell',
                label: '',
                type: FormInputType.MultiItemList,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        label: 'In-Building Small Cell',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '5',
                        placeholder: 'Number of Buliding Floors',
                        unit: 'floors',
                    },
                ]
            },
            {
                reference: 'small-cell',
                label: 'Small Cell Radio',
                type: FormInputType.MultiItemList,
                multiple: true,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'private-lte',
                label: 'Private LTE',
                type: FormInputType.MultiItemList,
                multiple: true,
                help: {
                    route: '/assets/help-pages/estimate/products/privateLTE.html',
                    class: HelpModalClass.Tiny
                },
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },
                ]
            },
            {
                reference: 'support-services-label',
                label: 'Support Services',
                type: FormInputType.Label,
            },
            {
                reference: 'network-management',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Years',
                        unit: 'years',
                        min: 10,
                        max: 20,
                    },
                ]
            },
            {
                reference: 'ongoing-maintenance',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Years',
                        unit: 'years',
                        min: 10,
                        max: 20,
                    },
                ]
            },
        ]
    },
    {
        reference: 'iot',
        fields: [
            {
                reference: 'smart-infrastructure-label',
                label: 'Smart Infrastructure',
                type: FormInputType.Label,
            },
            {
                reference: 'kiosk',
                label: '',
                type: FormInputType.MultiItemList,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '0',
                        label: 'Smart Kiosk',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'smart-parking-label',
                label: 'Smart Parking',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/smartparking.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'parking-spaces',
                label: '',
                type: FormInputType.MultiItemList,
                multiple: false,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '0',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    }, {
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
            {
                reference: 'traffic-cameras',
                label: '',
                type: FormInputType.MultiItemList,
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '0',
                        label: 'New Traffic Cameras',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },{
                        type: FormInputType.Checkbox,
                        reference: 'install',
                        grow: '0',
                        label: 'Install',
                    },
                ]
            },
        ]
    },
    {
        reference: 'remote-tech',
        fields: [
            // {
            //     reference: 'digital-wellness',
            //     label: 'Digital Wellness',
            //     type: FormInputType.MultiItemList,
            //     multiple: true,
            //     // help: {
            //     //     route: '/assets/help-pages/estimate/products/digital-wellness.html',
            //     //     class: HelpModalClass.Tiny
            //     // },
            //     fields: [
            //         {
            //             type: FormInputType.DropDown,
            //             reference: 'id',
            //             grow: '1',
            //             placeholder: 'Select Type',
            //         },{
            //             type: FormInputType.Input,
            //             reference: 'quantity',
            //             inputType: 'number',
            //             grow: '0',
            //             placeholder: 'Quantity',
            //             unit: 'users',
            //         },{
            //             type: FormInputType.Checkbox,
            //             reference: 'install',
            //             grow: '0',
            //             label: 'Install',
            //         },
            //     ]
            // },
            {
                reference: 'digital-wellness',
                label: 'Digital Wellness',
                type: FormInputType.NutrioDigitalWellness,
            },
            {
                reference: 'tele-medicine-label',
                label: 'Telemedicine',
                useCase: 'remote-tech',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/tele-medicine.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'tele-medicine',
                label: 'I\'m interested in Telemedicine solutions',
                useCase: 'remote-tech',
                type: FormInputType.Checkbox,
            },
            {
                reference: 'remote-learning-label',
                label: 'Remote Learning',
                useCase: 'remote-tech',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/remote-learning.html',
                    class: HelpModalClass.Small
                },
            },
            {
                reference: 'remote-learning',
                label: 'I\'m interested in remote learning solutions',
                useCase: 'remote-tech',
                type: FormInputType.Checkbox,
            },
            {
                reference: 'remote-work-label',
                label: 'Remote Work',
                useCase: 'remote-tech',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/products/remote-work.html',
                    class: HelpModalClass.Small
                },
            },
            {
                reference: 'remote-work',
                label: 'I\'m interested in remote work solutions',
                useCase: 'remote-tech',
                type: FormInputType.Checkbox,
            },
        ]
    },
]

export const existingProductDetails: UseCaseProductDetail[] = [
    {
        reference: 'savings-share-lighting-interior',
        fields: [
            {
                reference: 'lights',
                label: 'Lighting',
                useCase: 'smart-corridor',
                type: FormInputType.MultiItemList,
                multiple: true,
                help: {
                    route: '/assets/help-pages/estimate/existing/lighting.html',
                    class: HelpModalClass.Tiny
                },
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },
                ]
            },
            {
                reference: 'cost-per-kwh',
                label: 'Average Cost per kWh',
                useCase: 'smart-corridor',
                type: FormInputType.Input,
                placeholder: 'Quantity',
            },
        ]
    },
    {
        reference: 'savings-share-lighting-exterior',
        fields: [
            {
                reference: 'lights',
                label: 'Lighting',
                useCase: 'smart-corridor',
                type: FormInputType.MultiItemList,
                multiple: true,
                help: {
                    route: '/assets/help-pages/estimate/existing/lighting.html',
                    class: HelpModalClass.Tiny
                },
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '1',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },
                ]
            },
            {
                reference: 'cost-per-kwh',
                label: 'Average Cost per kWh',
                useCase: 'smart-corridor',
                type: FormInputType.Input,
                placeholder: 'Quantity',
            },
        ]
    },
    {
        reference: 'savings-share-infrastructure',
        fields: [
            {
                reference: 'vehicles',
                label: 'Vehicles',
                useCase: 'savings-share',
                type: FormInputType.MultiItemList,
                multiple: true,
                help: {
                    route: '/assets/help-pages/estimate/existing/vehicles.html',
                    class: HelpModalClass.Tiny
                },
                fields: [
                    {
                        type: FormInputType.DropDown,
                        reference: 'id',
                        grow: '1',
                        placeholder: 'Select Type',
                        // options: [
                        //     {label: 'Police Vehicles', value: 'police'},
                        //     {label: 'Busses', value: 'bus'},
                        //     {label: 'Vehicles (Non Bus or Police)', value: 'vehicle'},
                        // ]
                    },{
                        type: FormInputType.Input,
                        reference: 'quantity',
                        inputType: 'number',
                        grow: '0',
                        placeholder: 'Quantity',
                        unit: 'each',
                    },
                ]
            },
            // {
            //     reference: 'balance-infrastructure',
            //     label: 'Balance Infrastructure',
            //     useCase: 'savings-share',
            //     type: FormInputType.MultiItemList,
            //     multiple: true,
            //     fields: [
            //         {
            //             type: FormInputType.DropDown,
            //             reference: 'balance-infrastructure',
            //             grow: '1',
            //             placeholder: 'Select Type',
            //             // options: [
            //             //     {label: 'Sewer', value: 'sewer'},
            //             //     {label: 'Roads', value: 'road'},
            //             // ]
            //         },
            //     ]
            // },
            {
                reference: 'buildings-label',
                label: 'Buildings',
                useCase: 'savings-share',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/existing/buildings.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'buildings',
                label: 'Number of Buildings',
                useCase: 'savings-share',
                type: FormInputType.Input,
                placeholder: 'Quantity',
                unit: 'each',
            },
            {
                reference: 'building-size',
                label: 'Average Size',
                useCase: 'savings-share',
                type: FormInputType.Input,
                placeholder: 'Sq Ft',
                unit: 'sq ft',
            },
            {
                reference: 'smart-water-label',
                label: 'Smart Water',
                useCase: 'savings-share',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/existing/smartmeters.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'smart-water-meters',
                label: 'Smart Water Meters',
                useCase: 'savings-share',
                type: FormInputType.Input,
                placeholder: 'Quantity',
                unit: 'each',
            },
            {
                reference: 'smart-parking-label',
                label: 'Smart Parking',
                useCase: 'iot',
                type: FormInputType.Label,
                help: {
                    route: '/assets/help-pages/estimate/existing/smartparking.html',
                    class: HelpModalClass.Tiny
                },
            },
            {
                reference: 'parking-spaces',
                label: 'Existing Parking Spaces',
                useCase: 'iot',
                type: FormInputType.Input,
                placeholder: 'Quantity',
                unit: 'each',
            },
            {
                reference: 'parking-revenue',
                label: 'Annual Parking Revenue',
                useCase: 'iot',
                type: FormInputType.Input,
                placeholder: 'Quantity',
                unit: '$',
            },
        ]
    },
]